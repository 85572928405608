<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-4 col-xl-4 mx-auto">
        <div class="card">
          <div class="auth-form-wrapper px-4 py-5">
            <h4 class="mb-4 justify" align="center">Reset your password</h4>
            <b-alert dismissible :variant="alert_opt.variant" :show="alert_opt.show">{{ alert_opt.message }}</b-alert>
            <form class="forms-sample" @submit.prevent="reset_pwd">
              <div class="row">
                <div class="col-xl-12 col-md-12">
                  <div class="form-group">
                    <label for="input-company-name">Please input your email</label>
                    <input type="text" class="form-control" id="input-email" placeholder="Input your email" name="email" required v-model="form.email">
                  </div>
                </div>
              </div>
              <div class="mt-1" align="center">
                <button type="submit" class="btn btn-primary text-white mr-2 mb-2 mb-md-0">Reset password</button>
                <a @click="(e) => { e.preventDefault(); $router.push('/auth/login'); }" href="/auth/login" class="d-block mt-3 text-muted">or Sign in</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import authAPI from '../../../api/auth';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY });

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Reset Password',
  },
  data() {
    return {
      form: {
        email: '',
        captcha_token: '',
      },
      alert_opt: {
        variant: 'success',
        show: false,
        message: this.$t('auth.success.init_reset_password'),
      },
    };
  },
  methods: {
    async reset_pwd() {
      try {
        const loader = this.$loading.show();
        await this.$recaptchaLoaded();
        this.form.captcha_token = await this.$recaptcha();
        await authAPI.init_reset_password(this.form)
          .then(async (res) => {
            this.alert_opt.show = true;
            if (res.error) {
              this.alert_opt.variant = 'warning';
              this.alert_opt.message = this.$t(`auth.error.init_password_${res.error.title}`);
              return;
            }
            setTimeout(() => {
              this.$router.push('/auth/login');
            }, 10 * 1000);
          })
          .catch((err) => {
            console.log(err);
            this.alert_opt = {
              show: true,
              variant: 'warning',
              message: this.$t('general.error.general'),
            };
          });
        loader.hide();
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeMount() {
    this.$recaptchaInstance.showBadge();
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>
